<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>

              <div class="d-flex align-items-center">
                <el-button class="btn new-btn mr-3" @click="$router.push({path: '/banco'})">Cadastrar Novo</el-button>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>

            <div class="col-12">
              <el-table :data="queriedData.filter(data => !searchQuery || data.name.toLowerCase().includes(searchQuery.toLowerCase()))" v-if="total > 0">
                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"></el-table-column>
                <el-table-column width="108" v-if="total > 0" align="center" label="Ações">
                  <template v-slot="props">
                    <el-button type="warning" icon="tim-icons icon-pencil" plain circle @click="edit(props.$index, props.row)" size="small"></el-button>
                    <el-button type="danger" icon="tim-icons icon-simple-remove" plain circle @click="handleDelete(props.$index, props.row)" size="small"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div slot="footer" class="col-12 mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <p class="card-category table-footer" v-if="total > 0">
                Exibindo de {{ from + 1 }} à {{ to }} de {{ total }} registros
              </p>
              <p class="card-category table-footer" v-if="total === 0">
                Nenhum registro encontrado.
              </p>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total" v-if="total > 0"></base-pagination>
          </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { BasePagination } from '@/components'
import Fuse from 'fuse.js'
import swal from 'sweetalert2'
export default {
  data () {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: [],
      tableColumns: [],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  methods: {
    edit (index, row) {
      return this.$router.push(`/banco/${row.id}`)
    },
    handleDelete (index, row) {
      swal.fire({
        title: 'Você tem certeza?',
        text: `Você está prestes a deletar "${row.name}".`,
        showCancelButton: true,
        confirmButtonText: 'Sim, eu quero deletar!',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.value) {
          swal.fire({
            title: 'Deletando',
            text: `Aguarde, estamos deletando "${row.name}".`,
            onBeforeOpen: () => {
              swal.showLoading()
            }
          })
          this.deleteRow(row).then(status => {
            if (status) {
              swal.fire({
                title: 'Deletado!',
                text: `Você deletou "${row.name}"`,
                showConfirmButton: false,
                timer: 1000
              })
            } else {
              swal.fire({
                title: 'Error!',
                text: `Ocorreu um erro ao deletar "${row.name}"`,
                denyButtonText: 'Ok',
                showDenyButton: true,
                showConfirmButton: false
              })
            }
          })
        }
      })
    },
    async deleteRow (row) {
      let status
      try {
        status = (await this.$http.delete(`banks/${row.id}`)).status
      } catch (e) {
        status = e.response.status
      }
      if (status === 200) {
        const indexToDelete = this.tableData.findIndex(
          tableRow => tableRow.id === row.id
        )
        if (indexToDelete >= 0) {
          this.tableData[indexToDelete].status = 'Inativo'
        }
        return true
      }
      return false
    },
    async getData () {
      this.tableData = (await this.$http.get('banks')).data.data

      this.tableColumns = [
        {
          prop: 'id',
          label: 'Id',
          minWidth: 40
        },
        {
          prop: 'code',
          label: 'Código',
          minWidth: 40
        },
        {
          prop: 'name',
          label: 'Nome',
          minWidth: 200
        },
        {
          prop: 'status',
          label: 'Status',
          minWidth: 40
        }
      ]

      this.propsToSearch = ['title', 'type']
    }
  },
  mounted () {
    // Fuse search initialization.
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['name', 'email'],
      threshold: 0.3
    })
    this.getData()
  },
  watch: {
    searchQuery (value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  components: {
    BasePagination
  },
  computed: {
    queriedData () {
      let result = this.tableData
      if (this.searchedData.length > 0) {
        result = this.searchedData
      }
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length
    },
    currentTable () {
      const msg = `lista de ${this.$route.name}`
      return msg.toUpperCase()
    }
  }
}
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
</style>
